import { Chromatography } from './sample.chromatography.model';
import { TrackingStatus } from './tracking.status.model';

export class SampleStateData {
  last_state: TrackingStatus;
  method: string;
  sample_id: string;
  sm_content: {
    chromatography: Chromatography
  }; // Content object from sample_metadata table
  states: TrackingStatus[];
  updated: string;
  version: string;
}