import { Injectable } from '@angular/core';
import { LibraryData } from './models/library-data.model';

@Injectable({
  providedIn: 'root'
})
export class MethodDataService {

  _data: LibraryData[] = [];

  constructor() { }

  // Data set when CisServerService/getAllLibraryData() called
  public set data(ld: LibraryData[]) {
    this._data = ld;
  }

  public get data() {
    return this._data;
  }

  // Hardcoding this in case query to backend for method data fails
  public get version() {
    return 'LCB2023';
  }
}
